import { Link, useLocation } from "react-router-dom";
import parse from 'html-react-parser'
import { useEffect, useState } from 'react';
import ProjectsApi from '../api';
import ShareButton from "@/components/ShareButton";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import Loader from '@/components/Loader'

export default function Project() {
	const location = useLocation()
  	const shop = location.state.shop
	const [initDataUnsafe, initData] = useInitData();
	const [loading, setLoading] = useState(true);

	const [coupons, setCoupons] = useState([])
	useEffect(() => {
		ProjectsApi.getCoupons({shop: shop.id, initData: initData}).then(function(result) {
			if (result.data) setCoupons(result.data.coupons);
			setLoading(false)
		})
	}, [shop, initData])

	return (
		<>
			<div className='boxList'>
				<div className='boxList_item'>
					<div className='boxList_item-icon'>
						{shop.image && <img src={shop.host + '/i/100x100/' + shop.image} alt='' />}
					</div>
					<div className='boxList_item-content'>
						<div className='boxList_item-title'>{shop.title}</div>
						<div className='boxList_item-hint'>{shop.category}</div>
					</div>
					{shop.url &&
						<div className='boxList_item-right'>
							<a href={shop.url} rel='noreferrer' target='_blank' className='btn btn--sm'>Перейти</a>
						</div>
					}
				</div>
			</div>

			{shop.description &&
				<div className='boxText'>
					<div className='boxText_body'>
						{parse(shop.description)}
					</div>
				</div>
			}

			{loading ? <Loader/> : null}

			{coupons.map((coupon) => (
				<div className='boxText' key={coupon.id}>
					<div className='boxText_body'>
						<p><b>{coupon.title}</b></p>
						<Link className='btn btn--block btn--tt-none'
							to={'/projects/' + shop.id + '/discount/' + coupon.id}
							state={{coupon: coupon, shop: shop, from: location.pathname}}>
								Подробнее
						</Link>
					</div>
				</div>
			))}

			{coupons.length > 0 && <ShareButton url={'SH' + shop.id} text={shop.title} />}
		</>
	)
}
