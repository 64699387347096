import { useLocation, useParams } from "react-router-dom";
import ProjectsList from "@/features/Projects/components/ProjectsList";
import Page from '@/components/Page';
import { useEffect, useState } from "react";
import CategoriesApi from "../api";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import Loader from '@/components/Loader'

export default function CategoryPage() {
	const location = useLocation()
	const params = useParams()
	const [cat, setCat] = useState()
	const [InitDataUnsafe, initData] = useInitData();

	useEffect(() => {
		if (params.id && (location.state === null || (location.state !== null && typeof location.state.cat === 'undefined'))) {
			CategoriesApi.getCategory({id: params.id, initData: initData}).then(function(result) {
				if (result.data) {
					setCat(result.data.category)
					location.state = {
						cat: result.data.category,
						from: '/categories'
					}
				}
			})
		} else {
			setCat(location.state.cat)
		}
	}, [location, params, initData])

	return (
		cat ?
			<Page title={cat.title}>
				<div className='boxTitle'>{cat.title}</div>
				<ProjectsList categoryId={cat.id}/>
			</Page>
		: <Loader/>
	)
}
